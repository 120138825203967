@use '@fixed-size' as *;
@use '@typography' as *;
@use '@content' as *;
@use '@queries' as *;

.container {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  transition: all 0.12s ease;
  @include hideScrollbar;

  @include min-428-break {
    max-width: max-content;
    flex-wrap: nowrap;
  }

  @include min-1024-break {
    gap: 8px;
  }
}

.tabs-item,
.tabs-item-card,
.tabs-item-filter,
.tabs-item-navigation {
  all: unset;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 8px;
  p {
    @include weight-semi-bold;
  }
  transition: all 0.15s ease-in-out;

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom right,
      rgba(217, 217, 217, 0.3) 50%,
      rgba(217, 217, 217, 0.9) 50%
    );
  }

  p {
    text-overflow: ellipsis;
  }

  position: relative;

  .badge {
    position: absolute;

    &.one-number {
      top: 0;
      right: 0;
    }

    &.two-numbers {
      top: 0;
      right: -8px;

      @include min-1440-break {
        right: -5px;
      }
    }

    &.three-numbers {
      top: 0;
      right: -15px;

      @include min-1440-break {
        right: -12px;
      }
    }
  }
}

.variant {
  /*primary*/
  &--primary {
    background: var(--cst-tabitem-primary-background-default);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-default);

    &.tab-icon {
      color: var(--cst-cards-payment-method-icon-active);
      svg:not(.tag-category) {
        path {
          fill: var(--cst-cards-payment-method-icon-active);
        }
      }
    }

    p {
      color: var(--cst-tabitem-primary-text-default);
    }

    &:hover {
      background: var(--cst-tabitem-primary-background-hover);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-hover);

      p {
        color: var(--cst-tabitem-primary-text-hover);
      }
    }
    &:focus-visible {
      background: var(--cst-tabitem-primary-background-focused);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-focused);

      p {
        color: var(--cst-tabitem-primary-text-focused);
      }
    }
    &:active {
      background: var(--cst-tabitem-primary-background-pressed);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-pressed);

      p {
        color: var(--cst-tabitem-primary-text-pressed);
      }
    }
  }
  &--primary.active {
    background: var(--cst-tabitem-primary-background-pressed);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-primary-outline-pressed);

    p {
      color: var(--cst-tabitem-primary-text-pressed);
    }
  }
  /*primary*/

  /*secondary*/
  &--secondary {
    background: var(--cst-tabitem-secondary-background-default);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-default);
    color: #eb3c3c;
    &.tab-icon {
      svg:not(.tag-category) {
        path {
          fill: #eb3c3c;
        }
      }
    }

    p {
      color: var(--cst-tabitem-secondary-text-default);
    }

    &:hover {
      background: var(--cst-tabitem-secondary-background-hover);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-hover);

      p {
        color: var(--cst-tabitem-secondary-text-hover);
      }
    }
    &:focus-visible {
      background: var(--cst-tabitem-secondary-background-focused);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-focused);

      p {
        color: var(--cst-tabitem-secondary-text-focused);
      }
    }
    &:active {
      background: var(--cst-tabitem-secondary-background-pressed);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-pressed);

      p {
        color: var(--cst-tabitem-secondary-text-pressed);
      }
    }
  }
  &--secondary.active {
    background: var(--cst-tabitem-secondary-background-pressed);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-secondary-outline-pressed);

    p {
      color: var(--cst-tabitem-secondary-text-pressed);
    }
  }
  /*secondary*/

  /*quaternary*/
  &--quaternary {
    background: var(--cst-tabitem-quaternary-background-default);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-quaternary-outline-default);

    p {
      color: var(--cst-tabitem-quaternary-text-default);
      @include weight-medium;
    }

    &:hover {
      background: var(--cst-tabitem-quaternary-background-hover);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-quaternary-outline-hover);

      p {
        color: var(--cst-tabitem-quaternary-text-hover);
      }
    }
    &:focus-visible {
      background: var(--cst-tabitem-quaternary-background-focused);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-quaternary-outline-focused);

      p {
        color: var(--cst-tabitem-quaternary-text-focused);
      }
    }
    &:active {
      background: var(--cst-tabitem-quaternary-background-pressed);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-quaternary-outline-pressed);

      p {
        color: var(--cst-tabitem-quaternary-text-pressed);
      }
    }
  }
  &--quaternary.active {
    background: var(--cst-tabitem-quaternary-background-pressed);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-quaternary-outline-pressed);

    p {
      color: var(--cst-tabitem-quaternary-text-pressed);
    }
  }
  /*quaternary*/

  /*tertiary*/
  &--tertiary {
    background: var(--cst-tabitem-tertiary-background-default);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-default);

    p {
      color: var(--cst-tabitem-tertiary-text-default);
    }
    color: #40bb18;
    &.tab-icon {
      svg:not(.tag-category) {
        path {
          fill: #40bb18;
        }
      }
    }

    &:hover {
      background: var(--cst-tabitem-tertiary-background-hover);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-hover);

      p {
        color: var(--cst-tabitem-tertiary-text-hover);
      }
    }
    &:focus-visible {
      background: var(--cst-tabitem-tertiary-background-focused);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-focused);

      p {
        color: var(--cst-tabitem-tertiary-text-focused);
      }
    }
    &:active {
      background: var(--cst-tabitem-tertiary-background-pressed);
      box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-pressed);

      p {
        color: var(--cst-tabitem-tertiary-text-pressed);
      }
    }
  }
  &--tertriary.active {
    background: var(--cst-tabitem-tertiary-background-pressed);
    box-shadow: inset 0 0 0 2px var(--cst-tabitem-tertiary-outline-pressed);

    p {
      color: var(--cst-tabitem-tertiary-text-pressed);
    }
  }
  /*tertiary*/
}

.width {
  &--content {
    width: max-content;
  }

  &--full-w {
    width: 100%;
  }
}

.tabs-item-card {
  padding: 10px;
  border-radius: var(--cornerradius10);
  background: var(--cst-tabs-cards-background-default);

  &:hover:not(.active) {
    background: var(--cst-tabs-cards-background-hover);
  }

  &.active {
    background: var(--cst-tabs-cards-background-active);
    box-shadow: inset 0 0 0 2px var(--cst-tabs-cards-outline-active);
  }
}

.tabs-item-filter {
  gap: 10px;
  padding: 11px 18px;
  border-radius: 10px;
  background: var(--cst-tabs-item-filter-background-active);
  box-shadow: none;

  p {
    color: var(--cst-tabs-item-filter-text-default);
    font-size: var(--font-size-medium);
    font-family: Inter, sans-serif;
    line-height: var(--line-height-medium);
    font-weight: var(--font-weight-medium);
  }

  &:hover:not(.active) {
    background: var(--cst-tabs-cards-background-hover);
    box-shadow: inset 0 0 0 2px var(--cst-tabs-item-filter-outline-hover);

    p {
      color: var(--cst-tabs-item-filter-text-hover);
    }
  }

  &.active {
    background: var(--cst-tabs-item-filter-background-default);
    box-shadow: inset 0 0 0 2px var(--cst-tabs-item-filter-outline-default);

    p {
      color: var(--cst-tabs-item-filter-text-active);
    }
  }
}

.tabs-item-navigation {
  gap: 2px;
  flex-direction: column;
  padding: 0;
  @include fixedSize(46px, 47px);

  @include min-1440-break {
    @include fixedSize(55px, 50px);
  }

  p {
    color: #bfbfbf;
    font-size: var(--font-size-small);
    font-family: Inter, sans-serif;
    line-height: var(--line-height-small);
    font-weight: var(--font-weight-medium);
  }

  &:hover:not(.active) {
    p {
      color: #8f8f8f;
    }
  }

  &.active {
    p {
      color: #606060;
    }
  }
}

.tabs-item-search {
  background-color: #f6f6f6;
  border-radius: var(--cornerradius8);
  padding: 8px 18px 8px 18px;
  width: max-content;
  border: transparent;

  cursor: pointer;
}

.tabs-item-profile {
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;

  &.active {
    span {
      svg {
        color: #6941c6;
      }
    }

    .tab-profile-text {
      color: #6941c6;
    }
  }

  span {
    @include singleFixedSize(22px);

    @include min-428-break {
      @include singleFixedSize(28px);
    }

    svg {
      @include singleFixedSize(inherit);
      color: var(--cst-tabitem-profile-text-default);
    }
  }

  @include min-428-break {
    gap: 4px;
  }

  @include fixedSize(60px, 39px);

  @include min-428-break {
    @include fixedSize(84px, 50px);
  }

  @include min-744-break {
    @include fixedSize(143.2px, 50px);
  }

  .tab-profile-text {
    @include typography-xs;
    @include weight-medium;
    color: var(--cst-tabitem-profile-text-default);

    @include min-428-break {
      @include typography-s;
    }
  }
}
